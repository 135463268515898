@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Pathway Gothic One';
	src: url(https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap);

	font-family: 'Dancing Script';
	src: url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
}

@layer components {
	.card-images {
		@apply h-12 w-12 rounded-full border-2 border-ightGray overflow-hidden;
	}
	.avatars {
		@apply flex-row-reverse flex justify-center items-center pl-6;
	}
	.avatar-class {
		@apply relative  -ml-4 z-10 border-2 border-ightGray overflow-hidden rounded-full;
	}
	.textField {
		@apply relative border border-shadowColor appearance-none rounded-md w-full p-2 mt-0 focus:border-textFieldOutline focus:outline-none cursor-text text-mediumGray;
	}
	.datetextField {
		@apply relative border border-shadowColor appearance-none rounded-md w-full p-2 mt-0 focus:border-textFieldOutline focus:outline-none cursor-text text-mediumGray;
	}
	.loginTextField {
		@apply relative border text-base border-shadowColor appearance-none rounded-md w-full p-2 mt-0 focus:border-textFieldOutline focus:outline-none cursor-text;
	}
	.color-textField {
		@apply relative  appearance-none rounded w-full bg-white mt-0  focus:border-textFieldOutline focus:outline-none cursor-text;
	}
	.logos {
		@apply gap-y-2 flex flex-col w-full;
	}
	.new-textField-profile {
		@apply relative  border border-shadowColor appearance-none rounded-md w-full p-2 bg-white mt-0  focus:border-textFieldOutline focus:outline-none cursor-text;
	}
	.email-textField-profile {
		@apply relative  border border-shadowColor appearance-none cursor-default rounded-md w-full p-2 pl-0 bg-white mt-0 focus:border-textFieldOutline focus:outline-none text-ightGray;
	}
	.small-card {
		@apply md:w-4/12 p-6 bg-white shadow-md rounded-md;
	}
	.domain-textField {
		@apply relative w-full text-textPlaceholder appearance-none rounded-l-lg  py-3 px-3  focus:outline-none cursor-text border border-lightBorder border-r-0  flex;
	}
	.small-text {
		@apply text-base text-blackText leading-5;
	}
	.time-zone {
		@apply w-full bg-white border border-shadowColor rounded-md  pl-3 pr-5 focus:outline-none text-base;
	}
	.user-role {
		@apply w-full bg-white rounded-l-lg  p-2 focus:outline-none;
	}
	.textField-profile {
		@apply relative  border border-shadowColor appearance-none rounded-md w-full p-2 pl-0 bg-white mt-0 focus:border-textFieldOutline focus:outline-none cursor-text;
	}
	.textField-company {
		@apply relative  border border-shadowColor appearance-none rounded-md w-full p-2 pl-2 bg-white mb-4 focus:border-textFieldOutline focus:outline-none cursor-text;
	}
	.textField-workspace {
		@apply relative  border border-shadowColor appearance-none rounded-md w-full p-2 pl-2 bg-white mb-4 focus:border-textFieldOutline focus:outline-none cursor-text;
	}
	.textField-profile-password {
		@apply relative  border border-shadowColor appearance-none rounded-md w-full p-2 bg-white mt-0 focus:border-textFieldOutline focus:outline-none cursor-text;
	}

	/* //? buttons  */
	.uploadlogo-button {
		@apply hover:bg-addindigo bg-lightPurpleTheme w-full rounded-md text-white flex justify-center place-items-center py-2.5  px-2.5 focus:outline-none truncate transition duration-300;
	}

	.newCard {
		@apply hover:bg-addindigo bg-lightPurpleTheme w-full rounded-md text-white flex justify-center place-items-center h-10  focus:outline-none truncate transition duration-300;
	}
	.smallButton {
		@apply hover:bg-addindigo bg-lightPurpleTheme w-full rounded-md text-white flex justify-center place-items-center h-10  focus:outline-none truncate transition duration-300;
	}
	.create-button {
		@apply hover:bg-addindigo bg-lightPurpleTheme w-full rounded-md text-white flex justify-center place-items-center py-1.5 focus:outline-none truncate transition duration-300;
	}
	.invitation-button {
		@apply hover:bg-addindigo bg-lightPurpleTheme w-full rounded-md text-white flex justify-center place-items-center py-1.5 focus:outline-none truncate transition duration-300;
	}
	.purple-button {
		@apply hover:bg-addindigo bg-lightPurpleTheme w-full rounded-md text-white flex justify-center place-items-center py-1.5 focus:outline-none truncate transition duration-300;
	}
	.red-button-2 {
		@apply bg-radicalRed w-full rounded-md text-white flex justify-center place-items-center py-1.5 focus:outline-none hover:opacity-80 truncate transition duration-300;
	}

	.red-button {
		@apply bg-radicalRed w-full rounded-full text-white flex justify-center place-items-center py-1.5 focus:outline-none hover:opacity-80 truncate transition duration-300;
	}
	.signup-button {
		@apply hover:opacity-80  bg-lightPurpleTheme w-full rounded-lg text-white flex justify-center place-items-center py-1.5 focus:outline-none truncate transition duration-300;
	}
	.gray-button {
		@apply flex flex-row max-h-8 rounded-md px-2 py-0.5 bg-gallery border-gray94 border-2 text-mineShaft hover:bg-white focus:outline-none transition duration-300 truncate;
	}

	.dropdown-button {
		@apply flex flex-row max-h-10 w-full justify-center rounded-md px-2 py-1 bg-gray94 mb-2 border-gray95 border-2 text-mineShaft hover:bg-white focus:outline-none transition duration-300;
	}

	/*//? textToolbar button */
 .toolbar-button {
	 @apply  px-8 h-10 overflow-hidden truncate bg-white text-jacksonsPurple w-full cursor-pointer focus:outline-none  hover:bg-radicalRed hover:text-white flex justify-center place-items-center transition duration-300;
 }
 .font-class{
	 @apply pl-3 h-10 overflow-hidden truncate bg-white text-veryLightBlack w-full cursor-pointer focus:outline-none  hover:bg-selectionGray hover:text-white flex justify-center place-items-center transition duration-300;

 }

/* //? create card */

	.dropdown-menu {
		@apply w-60 md:-mt-5 -mt-44 md:-ml-0 -ml-10 bg-white p-3 border-gray95 border transition duration-300;
	}

	/* //? myCard */

	.draft-div {
		@apply shadow-md absolute px-3 py-1 top-2 left-2 bottom-auto  bg-opacity-70 bg-gray95 text-mineShaft  font-bold rounded focus:outline-none;
	}

	.status-div {
		@apply shadow-md absolute px-3 py-1 top-2 left-auto right-2  bottom-auto cursor-pointer  bg-jacksonsPurple text-white  font-semibold rounded  focus:outline-none;
	}

	/* //? persistance-drawer */
	.drawer-active-button {
		@apply max-h-8 rounded-full md:px-5 px-2 pt-1 pb-2 bg-radicalRed text-white focus:outline-none transition duration-300;
	}

	/* //? Header */
	.header-active-button {
		@apply text-radicalRed flex justify-end font-bold truncate text-lg;
	}
	.header-inactive-button {
		@apply text-jacksonsPurple flex justify-end font-bold truncate text-lg hover:opacity-80;
	}
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* @font-face {
  font-family: "Pathway Gothic One", sans-serif;
} */

::-webkit-scrollbar {
	width: 10px !important; /* width of the entire scrollbar */
	height: 10px !important; /* width of the entire scrollbar */
	/* right: 20px; */
}

::-webkit-scrollbar-track {
	background: white !important; /* color of the tracking area */
	border-radius: 5px;
	border: 1px solid lightgrey;
	/* margin: 20px 0px; */
}

::-webkit-scrollbar-thumb {
	background-color: #837cffd7; /* #f5b1b1 color of the scroll thumb */
	border-radius: 10px; /* roundness of the scroll thumb */
	border: 1.5px solid white;
	height: 10px !important;
}

::-webkit-scrollbar-track-piece {
	background: #fff;
}
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
